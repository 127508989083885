<div class="credits">
  <a href="https://maxencerobinet.fr">Designed and coded by Maxence Robinet</a>
</div>

<style lang="scss">
  .credits {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px;
    font-size: 12px;
    @media (max-width: 768px) {
      right: unset;
      left: 0;
      text-align: center;
      width: 100%;
    }
  }
</style>
