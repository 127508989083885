<script lang="ts">
  import { appStore, filterSettingStore, konvaStore, userStore } from "stores";

  import Konva from "konva";
  import { initFiltersValue } from "lib/default";
  import { handleFilterchange } from "lib/konva/filters";

  let {
    minBlurValue,
    maxBlurValue,
    minBrightnessValue,
    maxBrightnessValue,
    minContrastValue,
    maxContrastValue,
    minHueRotateValue,
    maxHueRotateValue,
    minOpacityValue,
    maxOpacityValue,
    minPixelateValue,
    maxPixelateValue,
    minNoiseValue,
    maxNoiseValue,
  } = initFiltersValue;
</script>

<div class="settings">
  {#if !$userStore.automaticMode}
    <form>
      <div class="line">
        <label for="blurRadius">blur</label>
        <input
          type="range"
          id="blurRadius"
          min={minBlurValue}
          max={maxBlurValue}
          step="0.3"
          bind:value={$filterSettingStore.blurRadius}
          on:input={() =>
            handleFilterchange($konvaStore.bgImage, Konva.Filters.Blur)}
        />
      </div>
      <div class="line">
        <label for="brightnessValue">Brightness</label>
        <input
          type="range"
          id="brightnessValue"
          min={minBrightnessValue}
          max={maxBrightnessValue}
          step="0.01"
          bind:value={$filterSettingStore.brightnessValue}
          on:input={() =>
            handleFilterchange($konvaStore.bgImage, Konva.Filters.Brighten)}
        />
      </div>

      <div class="line">
        <label for="contrastValue">Contrast</label>
        <input
          type="range"
          id="contrastValue"
          min={minContrastValue}
          max={maxContrastValue}
          bind:value={$filterSettingStore.contrastValue}
          on:input={() =>
            handleFilterchange($konvaStore.bgImage, Konva.Filters.Contrast)}
        />
      </div>

      <div class="line">
        <label for="hueRotateValue">Hue Rotate</label>
        <input
          type="range"
          id="hueRotateValue"
          min={minHueRotateValue}
          max={maxHueRotateValue}
          bind:value={$filterSettingStore.hueRotateValue}
          on:input={() =>
            handleFilterchange($konvaStore.bgImage, Konva.Filters.HSL)}
        />
      </div>

      <div class="line">
        <label for="opacityValue">Opacity</label>
        <input
          type="range"
          id="opacityValue"
          min={minOpacityValue}
          max={maxOpacityValue}
          step="0.01"
          bind:value={$filterSettingStore.opacityValue}
          on:input={() =>
            $konvaStore.bgImage.opacity($filterSettingStore.opacityValue)}
        />
      </div>

      <div class="line">
        <label for="pixelateValue">pixelate</label>
        <input
          type="range"
          id="pixelateValue"
          min={minPixelateValue}
          max={maxPixelateValue}
          step="0.5"
          bind:value={$filterSettingStore.pixelateValue}
          on:input={() =>
            handleFilterchange($konvaStore.bgImage, Konva.Filters.Pixelate)}
        />
      </div>
      <div class="line">
        <label for="noiseValue">Noise</label>
        <input
          type="range"
          id="noiseValue"
          min={minNoiseValue}
          max={maxNoiseValue}
          step="0.01"
          bind:value={$filterSettingStore.noiseValue}
          on:input={() =>
            handleFilterchange($konvaStore.bgImage, Konva.Filters.Noise)}
        />
      </div>
      <div class="row">
        <input
          type="checkbox"
          id="hideMainImage"
          bind:checked={$userStore.hideMainImage}
        />
        <label for="hideMainImage">Hide center image</label>
      </div>
      <div class="row colorPicker">
        <!-- color picker -->
        <input
        type="color"
        id="backgroundColor"
        bind:value={$userStore.backgroundColor}
        on:input={() => {
          $konvaStore.backgroundColorRect.fill($userStore.backgroundColor);
        }}
        />
        <label for="backgroundColor">Background color</label>
      </div>
    </form>
  {/if}
</div>

<style lang="scss">
  .settings {
    width: 100%;

    form {
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
    }

    label {
      text-transform: capitalize;
    }

    .line {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      & > label {
        width: 100%;
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
    }

    .colorPicker {
      margin: 1rem 0;
      display: flex;
      align-items: center;
      
      input {
        max-width: 50px;
        height: 28px;
        padding: 4px;
        margin-bottom: 0;
      }

      label {
        margin-bottom: 0;
        padding-left: 0.5rem;
      }
    }
  }
</style>
